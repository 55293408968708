import Link from 'next/link';
import { ReactElement } from 'react';
import { Box, Button, ButtonSize, ButtonVariant, Container, H1, P } from 'react-components';
import { LandingPageLayout } from 'shared/layouts/landing-page-layout';

export default function Custom404() {
  return (
    <Container minHeight="100vh" display="flex" flexDirection="column" justifyContent="center">
      <Box textAlign="center">
        <H1 mt={4} mb={3}>
          Page Not Found
        </H1>
        <P mb={4}>There are many paths, but this way leads nowhere, adventurer.</P>

        <Button as={Link} href="/" variant={ButtonVariant.DEFAULT} size={ButtonSize.LARGE}>
          Go home
        </Button>
      </Box>
    </Container>
  );
}

Custom404.getLayout = function getLayout(page: ReactElement) {
  return <LandingPageLayout>{page}</LandingPageLayout>;
};

Custom404.isProtected = false;
